<template>
  <section>
    <v-btn color="secondary" @click="showAddVersion = true">
      Agregar versión de cifrado
    </v-btn>

    <v-dialog v-model="showAddVersion" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Agregar versión de cifrado</span>
        </v-card-title>

        <v-card-text>
          <v-container class="my-4">
            <v-row class="d-flex align-center">
              <v-text-field
                type="number"
                v-model="versionCifrado.orden"
                outlined
                hide-details
                class="mr-2"
                label="Orden"
                clearable
              />
              <v-autocomplete
                v-model="versionCifrado.id_elemento"
                label="Estructura"
                required
                outlined
                :items="elementos"
                item-text="nombre"
                item-value="id"
                hide-details
                clearable
                return-object
              >
              </v-autocomplete>
              <v-checkbox
                v-model="versionCifrado.guion"
                label="Guión izquierdo"
                class="ml-2"
              />
              <v-btn
                :disabled="!versionCifrado.orden || !versionCifrado.id_elemento"
                solid
                color="secondary"
                class="ml-2"
                @click="agregarElementoNuevoCifrado()"
              >
                Agregar
              </v-btn>
            </v-row>

            <v-data-table
              class="mt-8"
              :headers="headersDialog"
              :items="nuevaVersionCifrado"
              sort-by="id"
            >
              <template v-slot:item.tipo_elemento="{ item }">
                <span>{{ item.tipo_elemento.nombre }}</span>
              </template>
              <template v-slot:item.guion="{ item }">
                <span>{{ item.guion ? "Si" : "No" }}</span>
              </template>
              <template v-slot:item.acciones="{ item }">
                <v-btn
                  icon
                  @click="eliminarElementoNuevoCifrado(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            min-width="30%"
            @click="showAddVersion = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            solid
            min-width="40%"
            @click="guardarNuevaVersionCifrado"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      class="mt-8"
      :headers="headers"
      :items="versiones"
      sort-by="id"
    >
      <template v-slot:item.estructura="{ item }">
        <span
          v-for="(elemento, index) in item.elementos"
          :key="index"
          class="d-block"
        >
          {{ elemento.posicion }} -
          {{ elemento.nombre_elemento }}
        </span>
      </template>
    </v-data-table>
  </section>
</template>
<script>
  export default {
    name: "VersionesComponent",
    data: () => ({
      showAddVersion: false,
      headers: [
        { text: "Código", value: "version" },
        { text: "Estructura", value: "estructura" },
      ],
      versiones: [],
      elementos: [],
      versionCifrado: {
        id_elemento: "",
        orden: "",
        guion: false,
      },
      headersDialog: [
        { text: "Posición", value: "posicion" },
        { text: "Tipo elemento cifrado", value: "tipo_elemento" },
        { text: "Guión izquierdo", value: "guion" },
        { text: "Acciones", value: "acciones" },
      ],
      nuevaVersionCifrado: [],
    }),
    methods: {
      async guardarNuevaVersionCifrado() {

        const response = await this.services.Cifrados.postVersionCifrado(
          { elementos: this.nuevaVersionCifrado }
        );

        if (response.status === 201) {
          this.pushAppMessage({
            message: "Versión de cifrado agregada correctamente",
            type: "success",
          });
          this.showAddVersion = false;
          this.nuevaVersionCifrado = [];
          this.getVersionesCifrado();
        } else {
          this.pushAppMessage({
            message: "Error al agregar la versión de cifrado",
            type: "error",
          });
        }
      },
      async getCatalogoElementos() {

        const response = await this.services.Cifrados.getElementsCifrados();
        this.elementos = response.data;
        this.elementos = [
          ...this.elementos,
          {
            id: 2,
            nombre: "Institución",
          },
        ];

      },
      agregarElementoNuevoCifrado() {
        this.nuevaVersionCifrado.push({
          posicion: this.versionCifrado.orden,
          tipo_elemento: this.elementos.find(
            (elemento) => elemento.id === this.versionCifrado.id_elemento.id
          ),
          guion: this.versionCifrado.guion,
          acciones: "",
        });
        this.versionCifrado.orden = '';
        this.versionCifrado.id_elemento = '';
      },
      eliminarElementoNuevoCifrado(item) {
        this.nuevaVersionCifrado = this.nuevaVersionCifrado.filter(
          (elemento) => elemento.posicion !== item.posicion
        );
      },
      async guardarVersionCifrado() {

        const response = await this.services.Cifrados.postVersionCifrado(
          this.nuevaVersionCifrado
        );

        if (response.status === 200 || response.status === 201) {
          this.pushAppMessage({
            message: "Versión de cifrado agregada correctamente",
            type: "success",
          });
          this.showAddVersion = false;
          this.nuevaVersionCifrado = [];
          this.versionCifrado = {
            id_elemento: "",
            orden: "",
            guion: false,
          };
          this.getVersionesCifrados();
        } else {
          this.pushAppMessage({
            message: "Ocurrió un error al agregar la versión de cifrado",
            type: "error",
          });
        }
      },
      async getVersionesCifrado() {

        const response = await this.services.Cifrados.getVersionesCifrados();
        this.versiones = response.data;

      },
    },
    async created() {
      await this.getCatalogoElementos();
      await this.getVersionesCifrado();
    },
  };
</script>
