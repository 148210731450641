<template>
  <section>
    <v-dialog v-model="showAddElement" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Agregar elemento de cifrado</span>
        </v-card-title>

        <v-card-text>
          <v-form class="mt-4" ref="formAddElement">
            <v-select 
              v-model="elementoCifrado.id_institucion"
              :items="institucionesUsuario" 
              item-text="nombre" 
              placeholder="Institución"
              label="Institución"
              item-value="id" 
              outlined
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="elementoCifrado.codigo"
              label="Código"
              required
              outlined
              maxlength="50"
              counter="50"
              :rules="[rules.required, rules.alphaNum]"
            ></v-text-field>
            <v-text-field
              v-model="elementoCifrado.nombre"
              label="Nombre"
              required
              outlined
              maxlength="255"
              counter="255"
              :rules="[rules.required, rules.alphaNum]"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            min-width="30%"
            @click="showAddElement = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            solid
            min-width="40%"
            @click="elementoCifrado?.id ? actualizarElementoCifrado() : agregarElementoCifrado()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="mt-8">
      <v-col cols="12" sm="5">
        <v-autocomplete
          v-model="filters.id_elemento"
          label="Seleccione un tipo de componente *"
          outlined
          :items="elementos"
          item-text="nombre"
          item-value="id"
          clearable
          @change="getCatalogoCifrados(), (showAddButton = true)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn
          class="mt-2"
          color="secondary"
          @click="(showAddElement = true), (elementoCifrado = {})"
          v-if="showAddButton"
        >
          Agregar elemento de cifrado
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-8" v-if="showAddButton">
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="filters.search"
          label="Buscar por nombre o código"
          outlined
          clearable
          hide-details
          @input="handlerGetCatalogoCifrados"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="5" v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])">
        <v-autocomplete
          v-model="filters.id_institucion"
          label="Seleccione una institución"
          outlined
          :items="instituciones"
          item-text="nombre"
          item-value="id"
          clearable
          hide-details
          @change="getCatalogoCifrados()"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <data-table-component
      :headers="headers"
      @paginar="paginate"
      :items="catalogo"
      v-models:pagina="paginaModel"
      v-models:select="porPaginaModel"
      :total_registros="filters.total_rows"
    >
      <template v-slot:[`item.institucion`]="{ item }">
        {{ item?.Institucion?.nombre ?? 'Uso general' }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn
          v-if="validarAccion(item)"
          color="secondary"
          icon
          small
          class="mr-4"
          @click="elementoCifrado = item, showAddElement = true"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-if="validarAccion(item)"
          color="secondary"
          icon
          small
          @click="selectedItem = item, showDeleteDialog = true"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </data-table-component>

    <confirmation-dialog-component
      :show="showDeleteDialog"
      title="¿Deseas eliminar el ítem?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="
        (showDeleteDialog = false), (selectedItem = null)
      "
      @confirm="eliminarElementoCifrado()"
    />
  </section>
</template>
<script>
  import DataTableComponent from "../../../components/DataTableComponent.vue";
  import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
  import { mapState } from "vuex";

  export default {
    name: "CatalogoComponent",
    components: {
      DataTableComponent,
      ConfirmationDialogComponent,
    },
    data: () => ({
      showAddElement: false,
      tipos: ["Tipo 1", "Tipo 2", "Tipo 3"],
      elementoCifrado: {
        id_institucion: null,
        tipo: "",
        codigo: "",
        nombre: "",
      },
      headers: [
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "TiposElementosCifrado.nombre",
        },
        { text: "Código", value: "codigo" },
        { text: "Nombre", value: "nombre" },
        { text: "Institución", value: "institucion" },
        { text: "Acciones", value: "acciones", align: "center" },
      ],
      page: 1,
      perPage: 10,
      filters: {
        page: 1,
        per_page: 10,
        total_rows: 0,
      },
      elementos: null,
      catalogo: [],
      showAddButton: false,
      selectedItem: null,
      showDeleteDialog: false,
      instituciones: [],
      interval: null,
      rules: {
        required: (value) => !!value || "Este campo es requerido",
        alphaNum: (value) =>
          /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/.test(value) ||
          "Este campo solo puede contener letras y números",
      },
    }),
    computed: {
      ...mapState(["selectedUnidad", "institucionesUsuario"]),
      porPaginaModel: {
        get() {
          return this.filters.per_page;
        },
        set(value) {
          this.setFiltroPorPagina(value);
        },
      },
      paginaModel: {
        get() {
          return this.filters.page;
        },
        set(value) {
          this.setFiltroPage(value);
        },
      },
    },
    methods: {
      async getCatalogoElementos() {

        const response = await this.services.Cifrados.getElementsCifrados();
        this.elementos = response.data;

      },
      async agregarElementoCifrado() {
        if (!this.$refs.formAddElement.validate()) return false;
        if (
          ["", " ", null].includes(this.elementoCifrado.codigo) ||
          ["", " ", null].includes(this.elementoCifrado.nombre)
        ) {
          this.temporalAlert({
            show: true,
            message: "Todos los campos son obligatorios",
            type: "warning",
          });
          return false;
        }


        this.elementoCifrado.tipo = this.filters.id_elemento;
        const response = await this.services.Cifrados.addElementoCifrado(
          this.elementoCifrado
        );

        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Elemento de cifrado agregado correctamente",
            type: "success",
          });
          this.elementoCifrado = {
            id_institucion: null,
            tipo: "",
            codigo: "",
            nombre: "",
          };
          this.showAddElement = false;
          this.getCatalogoCifrados();
        }

      },
      paginate(filters_params) {
        let params = {
          page: filters_params.pagina,
          per_page: filters_params.cantidad_por_pagina,
        };
        this.getCatalogoCifrados(params);
      },
      async getCatalogoCifrados(paginationFilters = {}) {
        if (this.filters.id_elemento === null) {
          this.catalogo = [];
          this.filters = {
            page: 1,
            per_page: 10,
            total_rows: 0,
          };
          return;
        }

        if (Object.keys(paginationFilters).length === 0) {
          this.filters.page = 1;
        }


        const response = await this.services.Cifrados.getCatalogoCifrados({
          ...this.filters,
          ...paginationFilters,
        });
        this.catalogo = response.data;
        this.filters.total_rows = Number(response.headers.total_rows);

      },
      async eliminarElementoCifrado() {

          const response = await this.services.Cifrados.deleteElementoCifrado(
            this.selectedItem.id
          );
          if (response.status === 200) {
            this.temporalAlert({
              show: true,
              message: "Elemento de cifrado eliminado correctamente",
              type: "success",
            });
            await this.getCatalogoCifrados();
            this.showDeleteDialog = false;
            this.selectedItem = null;
          }

      },
      validarAccion(item) {
        if (!this.haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])) {
          return this.institucionesUsuario.map((i) => i.id).includes(item.id_institucion);
        }

        return true;
      },
      async actualizarElementoCifrado() {
        if (!this.$refs.formAddElement.validate()) return false;
        if (
          ["", " ", null].includes(this.elementoCifrado.codigo) ||
          ["", " ", null].includes(this.elementoCifrado.nombre)
        ) {
          this.temporalAlert({
            show: true,
            message: "Todos los campos son obligatorios",
            type: "warning",
          });
          return false;
        }


        const response = await this.services.Cifrados.updateElementoCifrado(
          this.elementoCifrado
        );
        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            message: "Elemento de cifrado actualizado correctamente",
            type: "success",
          });
          this.elementoCifrado = {
            id_institucion: null,
            tipo: "",
            codigo: "",
            nombre: "",
          };
          this.showAddElement = false;
          this.getCatalogoCifrados();
        }

      },
      async getInstituciones() {

        const response = await this.services.Instituciones.getInstituciones({ pagination: false });
        this.instituciones = response.data;

      },
      async handlerGetCatalogoCifrados() {
        if (this.interval) {
          clearInterval(this.interval);
        }

        this.interval = setInterval(() => {
          this.getCatalogoCifrados();
          clearInterval(this.interval);
        }, 1000);
      },
    },
    async created() {
      await this.getCatalogoElementos();

      if (this.haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])) {
        this.getInstituciones();
      }
    },
  };
</script>
