<template>
  <section>
    <p class="text-h5 secondary--text">Administración de Instituciones</p>
    <v-row>
      <v-col cols="12" sm="12" md="2">
        <v-btn @click="showDialog = true" dark block color="secondary">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-text-field
          outlined
          label="Buscar"
          clearable
          placeholder="Ingrese el nombre o el código de la institución"
          @keyup="filterName"
          v-model="buscador"
          @click:clear="(buscador = null), getInstituciones()"
        />
      </v-col>

      <v-col cols="12" sm="12" md="3" lg="3">
        <v-autocomplete
            label="Estado"
            :items="ctlEstados"
            item-value="id"
            item-text="nombre"
            outlined
            clearable
            placeholder="Seleccione un estado"
            v-model="estadoFilter"
        />
      </v-col>
    </v-row>
    <p class="secondary--text ">Listado de Instituciones</p>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="editarInstitucion(item)"
              v-bind="attrs"
              v-on="on"
              large
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </template>

          <span>Editar</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <v-dialog persistent max-width="750" class="modal" v-model="showDialog">
      <v-card max-width="750">
        <v-btn @click="clearInfo" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">
                {{ editable ? "Editar" : "Agregar" }} institución
              </p>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Nombre *"
                  outlined
                  v-model="institucion.nombre"
                  @input="$v.institucion.nombre.$touch()"
                  :error-messages="nombreError"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Código *"
                  outlined
                  v-model="institucion.codigo"
                  @input="$v.institucion.codigo.$touch()"
                  :error-messages="codigoError"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  label="Estado *"
                  :items="ctlEstados"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  clearable
                  v-model="institucion.estado"
                  @input="$v.institucion.estado.$touch()"
                  :error-messages="estadoError"
                />
              </v-col>
            </v-row>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            class="text-capitalize mx-8 mb-2 mb-sm-0"
            outlined
            @click="clearInfo()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            @click="guardarInstitucion"
            class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0"
          >
            {{ editable ? "Editar" : "Agregar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import { helpers, required, numeric } from "vuelidate/lib/validators";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);
export default {
  name: "AdministracionInstituciones",
  components: {
    DataTableComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Código",
        value: "codigo",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado.nombre",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    showDialog: false,
    institucion: {
      id: null,
      nombre: null,
      codigo: null,
      estado: null,
    },
    editable: false,
    buscador: null,
    estadoFilter: null,
    timer: null,
    ctlEstados: [
      {
        id: 1,
        nombre: "En Configuración",
      },
      {
        id: 2,
        nombre: "Activa",
      },
      {
        id: 3,
        nombre: "Inactiva",
      },
    ],
  }),
  validations: {
    institucion: {
      nombre: { required, txtField },
      codigo: { required, numeric },
      estado: { required },
    },
  },
  computed: {
    nombreError() {
      const errors = [];
      if (!this.$v.institucion.nombre.$dirty) return errors;
      !this.$v.institucion.nombre.required &&
        errors.push("El campo nombre es requerido");
      !this.$v.institucion.nombre.txtField &&
        errors.push("El campo nombre solo admite caracteres alfanuméricos");
      return errors;
    },
    codigoError() {
      const errors = [];
      if (!this.$v.institucion.codigo.$dirty) return errors;
      !this.$v.institucion.codigo.required &&
        errors.push("El campo código es requerido");
      !this.$v.institucion.codigo.numeric &&
        errors.push("El campo código solo admite números");
      return errors;
    },
    estadoError() {
      const errors = [];
      if (!this.$v.institucion.estado.$dirty) return errors;
      !this.$v.institucion.estado.required &&
        errors.push("El campo estado es requerido");
      return errors;
    },
  },
  methods: {
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getInstituciones();
      }, 500);
    },
    async getInstituciones() {
      const { status, data, headers } =
        await this.services.Instituciones.getCatalogoInstituciones({
          page: this.page,
          per_page: this.perPage,
          busqueda: this.buscador,
          id_estado: this.estadoFilter,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getInstituciones();
    },
    clearInfo() {
      this.institucion.id = null;
      this.institucion.nombre = null;
      this.institucion.codigo = null;
      this.institucion.estado = null;
      this.showDialog = false;
      this.$v.$reset();
      this.editable = false;
      this.estadoFilter = null;
    },

    async guardarInstitucion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let response = null;

        if (this.editable) {
          response = await this.services.Instituciones.putCatalogoInstituciones(
            this.institucion.id,
            this.institucion
          );
        } else {
          response =
            await this.services.Instituciones.postCatalogoInstituciones(
              this.institucion
            );
        }

        if (response.status == 201 || response.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Institución ${
              this.editable ? "editada" : "guardada"
            } con éxito`,
            type: "success",
          });
          this.clearInfo();
          this.getInstituciones();
        }
      }
    },
    editarInstitucion(item) {
      this.institucion.id = item.id;
      this.institucion.nombre = item.nombre;
      this.institucion.codigo = item.codigo;
      this.institucion.estado = item.estado.id;
      this.editable = true;
      this.showDialog = true;
    },
  },
  watch:{
    estadoFilter(){
      this.getInstituciones();
    }
  },
  created() {
    this.getInstituciones();
  },
};
</script>
