<template>
  <section>
    <v-tabs v-model="id" background-color="bgMinsal" show-arrows>
      <v-tab
        v-for="item in contenido"
        :key="item.id"
        class="secondary--text"
        style="text-transform: none !important"
        v-if="!item.hide"
      >
        <v-icon>{{ item.icon }}</v-icon>
        <span class="pl-1">{{ item.tab }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="id">
      <v-tab-item v-for="item in contenido" :key="item.id" v-if="!item.hide">
        <component :is="item.content" class="mt-8"></component>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>
<script>
  import CatalogoComponent from "./components/CatalogoComponent.vue";
  import VersionesComponent from "./components/VersionesComponent.vue";

  export default {
    name: "MainAdminCatalogoView",
    data: () => ({
      id: 0,
    }),
    computed: {
      contenido() {
        return [
          {
            id: 1,
            tab: "Catalogo de elementos de cifrado",
            icon: "mdi-format-list-bulleted",
            content: CatalogoComponent,
          },
          {
            id: 2,
            tab: "Versiones de cifrado",
            icon: "mdi-clipboard-text",
            content: VersionesComponent,
            hide : this.haveRoles(["ROLE_UFI", "ROLE_UFI_TECNICO"])
          },
        ];
      },
    },
  };
</script>
